import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, CloseSidebar, OpenSidebar } from '@prenuvo/halo-icon';
import { Button, cn, Icon, IconButton, IconSource, Tooltip } from '@prenuvo/halo-web';
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';

import { useOrganValidation } from '@/store/useOrganValidation/useOrganValidation';
import { OrganValidationStatus } from '@/store/useOrganValidation/useOrganValidation.types';

import { NavMenuProps, Progress } from './NavMenu.types';
import { ProgressIndicator } from './ProgressIndicator';

export function NavMenu({
  isCollapsed,
  isReviewVisible = true,
  links,
  maxSize,
  minSize,
  onLinkSelect,
  onReviewClick,
  onToggleSideMenu,
  selectedLinkValue,
}: NavMenuProps) {
  const containerControls = useAnimationControls();
  const [selectedOrgan, setSelectedOrgan] = useState('');
  const linkItemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { getStatus } = useOrganValidation();

  const containerVariants = {
    close: {
      transition: {
        damping: 15,
        duration: 0.2,
        type: 'spring',
      },
      width: `${minSize}px`,
    },
    open: {
      transition: {
        damping: 20,
        duration: 0.4,
        type: 'spring',
      },
      width: `${maxSize}px`,
    },
  };

  useEffect(() => {
    if (selectedLinkValue) {
      setSelectedOrgan(selectedLinkValue);
    }
  }, [selectedLinkValue]);

  useEffect(() => {
    if (isCollapsed) {
      containerControls.start('close');
    } else {
      containerControls.start('open');
    }
  }, [isCollapsed]);

  useEffect(() => {
    if (selectedOrgan) {
      const linkItem = linkItemRefs.current.find(
        (item) => item?.getAttribute('data-selected') === 'true',
      );

      if (linkItem) {
        linkItem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [selectedOrgan]);

  const getStatusContent = (status: OrganValidationStatus | undefined) => {
    const result = ' - ';

    if (status?.error) {
      return `${result} Incomplete`;
    }

    if (status?.warning) {
      return `${result} ONCO/CSD 4+`;
    }

    return '';
  };

  return (
    <AnimatePresence>
      <motion.div
        animate={containerControls}
        data-collapsed={isCollapsed}
        data-testid="nav-menu"
        variants={containerVariants}
      >
        <nav
          className={cn(
            'rounded-2xl bg-stone-900 dark:bg-stone-900 pr-1 pt-3 overflow-hidden h-full flex flex-col justify-between',
            isCollapsed && `justify-items-center`,
          )}
        >
          <div className="custom-scrollbar grid w-full grid-flow-row auto-rows-max grid-cols-none gap-3 overflow-y-auto overflow-x-hidden pb-3">
            {links.map((link, index) => {
              const isActive = link.key === selectedOrgan;
              const isDisabled = false; // To be implemented

              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  key={link.key}
                  className={cn(
                    'group px-3 inline-flex items-center justify-center whitespace-nowrap text-sm font-medium text-neutral-400 dark:text-neutral-400 hover:no-underline',
                    {
                      'border-b border-dashed border-stone-700 py-2':
                        link.key === 'reason_for_scan',
                      'pointer-events-none text-neutral-800 dark:text-neutral-800': isDisabled,
                    },
                  )}
                  data-testid="nav-menu-link"
                  onClick={() => {
                    setSelectedOrgan(link.key);

                    if (onLinkSelect) {
                      onLinkSelect(link.key);
                    }
                  }}
                  to="#"
                >
                  <Tooltip
                    className={cn(
                      'bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50',
                      {
                        hidden: !isCollapsed && getStatusContent(getStatus(link.key)) === '',
                      },
                    )}
                    content={`${link.title} ${getStatusContent(getStatus(link.key))}`}
                    side="bottom"
                  >
                    <div
                      className={cn(
                        'flex w-full static px-3 py-4 hover:bg-stone-800 dark:hover:bg-stone-800 rounded-lg h-12 items-center hover:text-neutral-400 dark:hover:text-neutral-400',
                        {
                          'bg-stone-800 dark:bg-stone-800 text-neutral-50 dark:text-neutral-50 hover:text-neutral-50 dark:hover:text-neutral-50':
                            isActive,
                          'w-auto relative': isCollapsed,
                        },
                      )}
                      data-selected={`${isActive}`}
                      ref={(el: HTMLDivElement | null) => {
                        linkItemRefs.current[index] = el;
                      }}
                    >
                      {isCollapsed && (
                        <div>
                          <Icon
                            aria-label={link.title}
                            className="fill-current"
                            size="md"
                            source={link.icon as IconSource}
                          />
                        </div>
                      )}
                      {!isCollapsed && (
                        <span className="whitespace-normal text-base font-normal no-underline">
                          {link.title}
                        </span>
                      )}
                      {(getStatus(link.key)?.error || getStatus(link.key)?.warning) && (
                        <span className="ml-auto flex items-center">
                          <ProgressIndicator
                            isCollapsed={isCollapsed}
                            progress={
                              getStatus(link.key)?.error ? Progress.MISSING_INPUT : Progress.ONCORAD
                            }
                          />
                        </span>
                      )}
                    </div>
                  </Tooltip>
                </Link>
              );
            })}
          </div>
          <div>
            {isReviewVisible && (
              <div className="flex w-full content-center justify-center border-t border-dashed border-stone-700 p-3 dark:border-stone-700">
                <Tooltip
                  className={cn(
                    'bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50',
                    {
                      hidden: !isCollapsed,
                    },
                  )}
                  content="Review and Sign"
                  side="bottom"
                >
                  <Button
                    aria-label="Chevron right"
                    className={{ root: 'flex w-full items-center justify-center' }}
                    data-testid="review-sign-button"
                    onClick={onReviewClick}
                    rightIcon={ArrowRight}
                    variant="primary"
                  >
                    {!isCollapsed && <span>Review and Sign</span>}
                  </Button>
                </Tooltip>
              </div>
            )}
            <div
              className={cn(
                isCollapsed && 'w-full content-center justify-center',
                'py-6 px-4 border-t border-dashed border-stone-700 dark:border-stone-700',
                !isCollapsed && 'flex justify-end',
              )}
            >
              <Tooltip
                className="bg-stone-700 text-xs text-neutral-50 dark:bg-stone-700 dark:text-neutral-50"
                content={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
                side="bottom"
              >
                <IconButton
                  aria-label="Toggle sidebar"
                  className={{
                    icon: 'fill-neutral-400 hover:fill-neutral-50',
                  }}
                  data-testid="menu-toggle-button"
                  icon={isCollapsed ? OpenSidebar : CloseSidebar}
                  onClick={onToggleSideMenu}
                  size="md"
                  variant="text"
                />
              </Tooltip>
            </div>
          </div>
        </nav>
      </motion.div>
    </AnimatePresence>
  );
}
