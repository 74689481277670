import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest/makeApiRequest';
import { useHiStudy } from '@/store';
import { ScanDetails } from '@/store/useHiStudy/useHiStudy.type';

export const useScanDetails = (studyId?: string) => {
  const { setScanDetails } = useHiStudy();

  const fetchScanDetails = async () => {
    const { data } = await makeApiRequest<ScanDetails>(
      'APPS',
      `${API_ENDPOINTS.hi.study}/${studyId}/radiologistScanDetails`,
      'GET',
    );

    return data;
  };

  const {
    data: scanDetailsData,
    error,
    isLoading,
  } = useQuery({
    enabled: !!studyId,
    queryFn: fetchScanDetails,
    queryKey: ['radiologistScanDetails', studyId],
    refetchOnWindowFocus: false,
    retryOnMount: false,
  });

  useEffect(() => {
    if (scanDetailsData) {
      setScanDetails(scanDetailsData);
    }
  }, [scanDetailsData]);

  return { data: scanDetailsData, error, isLoading };
};
