import { useMemo } from 'react';
import { QuestionCircle } from '@prenuvo/halo-icon';
import { IconSource } from '@prenuvo/halo-web';

import { ORGAN_SECTIONS } from '@/mocks/constants';
import { useReportOrgan } from '@/store';
import { useFormStructure } from '@/store/useFormStructure/useFormStructure';
import type { OnDemandConditionsData } from '@/store/useOnDemandConditions/useOnDemandConditionsStore.types';
import { Finding } from '@/types/finding';
import { FormStructure } from '@/types/formStructure';
import { NormalizeCheckItem, ReportOrgan } from '@/types/shared';
import { parseOrganTitle } from '@/utils';

import { FindingReport } from '../Components/Finding';
import { SectionData } from '../Components/Section';

const getIconByFormStructure = (title: string, formStructure: FormStructure) => {
  const organ = formStructure.structure.find(
    (organData) => organData.key && organData.key === title,
  );

  return (organ?.icon || QuestionCircle) as IconSource;
};

const getNormalStatementsWithDefaultTrue = (reportOrgan: ReportOrgan) => {
  if (!reportOrgan?.normaliseCheck) {
    return [];
  }

  return Object.entries(reportOrgan.normaliseCheck)
    .filter(
      ([key, statement]) =>
        key.startsWith('norm_') && (statement as NormalizeCheckItem).defaultBool === true,
    )
    .map(([, statement]) => (statement as NormalizeCheckItem).value);
};

export const useTransformedReportData = (reportFindings: {
  [key: string]: Finding[];
}): SectionData[] => {
  const { formStructure, getFormDataByOrgan } = useFormStructure();

  const { reportOrgans } = useReportOrgan();
  const normalStatementData = Object.values(reportOrgans)[0] || {};

  const transformReportData = (reportFindingsData: { [key: string]: Finding[] }): SectionData[] =>
    Object.entries(ORGAN_SECTIONS).reduce((result, [section, organs]) => {
      const organsData = organs.reduce(
        (acc, organ) => {
          const findings = reportFindingsData[organ];
          const organFormData = getFormDataByOrgan(organ);

          const transformedFindings = findings?.map(
            (finding): FindingReport => ({
              actionDetails: finding.renderedSummary.actionDetails,
              actionSummary: finding.renderedSummary.actionSummary,
              csdScore: finding.renderedSummary.csdScore,
              error: null,
              findingDetails: finding.renderedSummary.findingDetails,
              findingSummary: finding.renderedSummary.findingSummary,
              observation: '',
              oncoradsScore: finding.renderedSummary.oncoradsScore,
              organ: finding.conditionTemplate.organ || '',
              progress: false,
              uuid: finding.renderedSummary.uuid,
            }),
          );

          if (organFormData) {
            acc.push({
              findings: transformedFindings || [],
              icon: getIconByFormStructure(organ, formStructure as FormStructure),
              normals: getNormalStatementsWithDefaultTrue(
                normalStatementData[organ] as ReportOrgan,
              ),
              title: parseOrganTitle(organFormData.key),
            });
          }

          return acc;
        },
        [] as {
          findings: FindingReport[];
          icon: IconSource;
          normals: string[];
          title: string;
        }[],
      );

      if (organsData.length > 0) {
        result.push({
          organs: organsData,
          sectionName: section,
        });
      }

      return result;
    }, [] as SectionData[]);

  return useMemo(() => transformReportData(reportFindings), [reportFindings, formStructure]);
};

export const mergeOrgansAndonDemandConditions = (
  organs: { [key: string]: Finding[] },
  onDemandConditons: {
    [key: string]: OnDemandConditionsData[];
  },
) =>
  Object.entries(onDemandConditons).reduce(
    (updatedOrgansData, [key, conditions]) => {
      const updatedOrgans = { ...updatedOrgansData };

      updatedOrgans[key] = conditions.reduce((organData, condition) => {
        const updatedOrganData = [...organData];

        if (condition.renderedSummary) {
          const updatedData = {
            conditionTemplate: { organ: key },
            error: null,
            observation: '',
            progress: false,
            renderedSummary: condition.renderedSummary,
          };

          const index = updatedOrganData.findIndex(
            (organ) => organ.renderedSummary.uuid === condition.renderedSummary!.uuid,
          );

          if (index !== -1) {
            updatedOrganData[index] = updatedData;
          } else {
            updatedOrganData.push(updatedData);
          }
        }

        return updatedOrganData;
      }, updatedOrgans[key] || []);

      return updatedOrgans;
    },
    { ...organs },
  );
