import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { useHiStudy } from '@/store';
import { StudyMedicalHistoryResponse } from '@/types/medicalHistory';

export const useStudyMedicalHistory = (studyId: string | undefined) => {
  const { setMedicalHistory } = useHiStudy();

  const fetchMedicalHistory = async () => {
    const { data } = await makeApiRequest<StudyMedicalHistoryResponse>(
      'HI',
      `${API_ENDPOINTS.hi.study}/${studyId}/medical-history`,
      'GET',
    );

    return data;
  };

  const {
    data: studyMedicalHistoryData,
    error,
    isLoading,
  } = useQuery({
    enabled: !!studyId,
    queryFn: fetchMedicalHistory,
    queryKey: ['medicalHistory', studyId],
  });

  useEffect(() => {
    if (studyMedicalHistoryData) {
      setMedicalHistory(studyMedicalHistoryData);
    }
  }, [studyMedicalHistoryData]);

  return { data: studyMedicalHistoryData, error, isLoading };
};
