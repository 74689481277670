import { FunctionComponent, Suspense } from 'react';

import { Loading } from '@/components';

export function Loadable(Component: FunctionComponent) {
  const LoadableComponent = function LoadableComponent(props: JSX.IntrinsicAttributes) {
    return (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    );
  };

  return LoadableComponent;
}
