/* eslint-disable no-console */
interface TabManagementOptions {
  onClose?: () => void;
  studyId: string;
}

// This is a feature that allows the user to close all the tabs opened by FCT
export const initializeTabManagement = ({ onClose, studyId }: TabManagementOptions) => {
  if (!studyId) {
    return null;
  }

  // Check if BroadcastChannel is supported
  if (!('BroadcastChannel' in window)) {
    console.warn('BroadcastChannel is not supported in this browser');
    
    return null;
  }

  // Create broadcast channel for this study
  const channel = new BroadcastChannel(`prenuvo-fct-study-${studyId}`);
  const openedWindows: Window[] = [];

  // Override window.open to track opened windows
  const originalWindowOpen = window.open;
  
  window.open = function customWindowOpen(url, ...args) {
    const newWindow = originalWindowOpen.call(window, url, ...args);

    if (newWindow) {
      openedWindows.push(newWindow);
    }
    
    return newWindow;
  };

  // Listen for close messages
  channel.onmessage = (event) => {
    if (event.data.type === 'CLOSE_STUDY' && event.data.studyId === studyId) {
      window.close();
    }
  };

  // Handle window being closed
  const handleBeforeUnload = () => {
    // Close all opened windows
    openedWindows.forEach(win => {
      try {
        win.close();
      } catch (e) {
        console.warn('Failed to close window:', e);
      }
    });

    // Broadcast the close message
    channel.postMessage({
      studyId,
      type: 'CLOSE_STUDY',
    });

    onClose?.();
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  // Return cleanup function
  return () => {
    window.open = originalWindowOpen;
    window.removeEventListener('beforeunload', handleBeforeUnload);
    channel.close();
  };
};
