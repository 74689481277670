import { cn } from '@prenuvo/halo-web';

import { DotIndicator } from '@/components/DotIndicator/DotIndicator';
import { Editor } from '@/components/Editor';
import { useReportOrgan as useReportOrganQuery } from '@/hooks/queries';
import { CSD_MESSAGES } from '@/mocks/constants';
import { useReport } from '@/store';
import { replaceConditionWithValue, transformSentenceToKebabCase } from '@/utils/utils';

import { splitFindingDetails } from '../../utils';
import { FindingProps } from './Finding.type';

export function Finding({ finding }: FindingProps) {
  const formattedOrganTitle = transformSentenceToKebabCase(finding.organ);
  const { actionDetails, actionSummary, findingDetails, findingSummary } = finding;
  const isEditorReadOnly = true;
  const { report } = useReport();

  useReportOrganQuery(report?.uuid || '', undefined);

  return (
    <div
      className="mt-3 space-y-10 border-b border-stone-700 pb-6"
      data-testid={`${formattedOrganTitle}-finding`}
    >
      <div className="space-y-1">
        {findingSummary && (
          <div className="text-lg font-bold" data-testid="finding-summary">
            <Editor
              isReadOnly={isEditorReadOnly}
              value={replaceConditionWithValue(findingSummary)}
            />
          </div>
        )}
        {finding.csdScore !== 0 && (
          <p
            className={`text-sm font-semibold ${CSD_MESSAGES[finding.csdScore as keyof typeof CSD_MESSAGES].color}`}
          >
            {CSD_MESSAGES[finding.csdScore as keyof typeof CSD_MESSAGES].message}
          </p>
        )}
      </div>
      {findingDetails.length > 1 && (
        <div>
          <ul className="list-disc px-5">
            {splitFindingDetails(findingDetails).map((detail, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={`${detail}-${index}`}
                className="text-neutral-50"
                data-testid="finding-detail"
              >
                {detail}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div
        className={cn('rounded-xl bg-neutral-950 p-4', {
          hidden: !actionSummary && !actionDetails,
        })}
      >
        {actionSummary && (
          <div className="mb-3 text-lg font-extrabold" data-testid="action-summary">
            <Editor
              isReadOnly={isEditorReadOnly}
              value={replaceConditionWithValue(actionSummary)}
            />
          </div>
        )}
        {actionDetails && (
          <div data-testid="action-detail">
            <Editor
              isReadOnly={isEditorReadOnly}
              value={replaceConditionWithValue(actionDetails)}
            />
          </div>
        )}
      </div>
      <div className="flex gap-4" data-testid="finding-dot-indicators">
        <DotIndicator filledDots={finding.oncoradsScore} isViewMode label="ONCO" />
        <DotIndicator filledDots={finding.csdScore} isViewMode label="CSD" />
      </div>
    </div>
  );
}
