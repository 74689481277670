import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { useFormStructure as useFormStructureStore } from '@/store/useFormStructure/useFormStructure';
import { FormStructureSummary } from '@/store/useFormStructure/useFormStructure.type';
import { FormStructure } from '@/types/formStructure';
import { transformStructureToNavList } from '@/utils/utils';

export const useFormStructure = (studyId: string | undefined) => {
  const { setFormStructure } = useFormStructureStore();
  const [formStructureData, setFormStructureData] = useState<FormStructureSummary>();

  const fetchFormStructure = () =>
    makeApiRequest<FormStructure[]>(
      'FCT',
      `${API_ENDPOINTS.organs.getOrgans}?sku=300&status=draft`,
      'GET',
    );

  const { data: formStructure, isLoading } = useQuery({
    enabled: !!studyId,
    queryFn: fetchFormStructure,
    queryKey: ['formStructure', studyId],
    refetchOnWindowFocus: false,
    retryOnMount: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (formStructure) {
      const transformedData = transformStructureToNavList(formStructure.data[0]);

      setFormStructure(transformedData);
      setFormStructureData(transformedData);
    }
  }, [formStructure]);

  return { formStructure: formStructureData, isLoading };
};
