import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChevronRight, Edit } from '@prenuvo/halo-icon';
import { Button, Checkbox, Dialog, Select, Switch, Typography } from '@prenuvo/halo-web';

import { Editor, Header } from '@/components';
import { Technique } from '@/components/ReasonForScanDisplay/components/Technique';
import { Section } from '@/components/Report/Components';
import {
  mergeOrgansAndonDemandConditions,
  useTransformedReportData,
} from '@/components/Report/Hooks';
import { Signature } from '@/components/Signature';
import { useAuthStore } from '@/core/store/auth/authStore';
import { useReport as useReportMutation } from '@/hooks/mutations';
import { useFormStructure, useReport as useReportQuery } from '@/hooks/queries';
import { useFinding, useReport as useReportStore } from '@/store';
import { useOnDemandConditionsStore } from '@/store/useOnDemandConditions/useOnDemandConditionsStore';

export function Report() {
  const { id: studyId } = useParams<{ id: string }>();

  const { user } = useAuthStore();

  useReportQuery();
  useFormStructure(studyId);
  const { mutate: updateReportMutation } = useReportMutation(studyId!);
  const { onDemandConditons } = useOnDemandConditionsStore();
  const { findings } = useFinding();
  const updatedOrgansData = mergeOrgansAndonDemandConditions(findings, onDemandConditons);
  const reportData = useTransformedReportData(updatedOrgansData);

  const [isSigned, setIsSigned] = useState(false);
  const [isSensitive, setIsSensitive] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);
  const { report, setReport } = useReportStore();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [addSecondSignature, setAddSecondSignature] = useState(false);
  const [isSecondSigned, setIsSecondSigned] = useState(false);
  const [radiologist, setRadiologist] = useState<string>('');

  const handleSaveReport = () => {
    if (report && user) {
      const reportDataObj = {
        authors: [user?.user_id],
        finalImpressions: report.finalImpressions,
        nextRecommendedScan: report.nextRecommendedScan,
        sensitive: isSensitive,
        status: report.status,
        studyId: report.studyId,
        urgent: isUrgent,
        uuid: report.uuid,
      };

      updateReportMutation(reportDataObj, {
        onSuccess: (reportObj) => {
          setReport(reportObj);
          setIsDialogOpen(true);
        },
      });
    }
  };

  const onAddSecondSignatureHandler = () => {
    if (addSecondSignature) {
      setRadiologist('');
      setIsSecondSigned(false);
    }

    setAddSecondSignature((prevState) => !prevState);
  };

  const onRadiologistSelectHandler = (value: string) => {
    setRadiologist(value);
  };

  if (!reportData.length) {
    return null;
  }

  return (
    <div className="flex h-screen flex-col">
      <Header />
      <div className="custom-scrollbar h-[calc(100vh-5rem)] overflow-y-auto">
        <Dialog
          data-testid="report-submitted-dialog"
          onOpenChange={(open) => setIsDialogOpen(open)}
          open={isDialogOpen}
          title={{
            children: 'Report Submitted',
          }}
        >
          <Typography className="text-neutral-400 dark:text-neutral-400" variant="paragraph-lg">
            Your report has been submitted successfully.
          </Typography>
        </Dialog>
        <div className="space-y-8">
          <div className="mt-4 px-[162px]">
            <Technique isReadOnly />
          </div>
          <div className="mt-4 space-y-4 px-[162px]">
            <div className="space-y-2">
              <Typography
                as="div"
                data-testid="final-impressions-title"
                variant="paragraph-lg-bold"
              >
                FINAL IMPRESSIONS:
              </Typography>
              {report?.finalImpressions && (
                <Typography as="p" data-testid="final-impressions-text">
                  <Editor isReadOnly value={report.finalImpressions} />
                </Typography>
              )}
            </div>
            <div className="space-y-2">
              <Typography as="div" data-testid="next-appointment-title" variant="paragraph-lg-bold">
                NEXT APPOINTMENT:
              </Typography>
              {report?.nextRecommendedScan !== 0 ? (
                <Typography as="p" data-testid="next-appointment-text">
                  A follow up scan in {report?.nextRecommendedScan} months is suggested for further
                  proactive health unless clinically indicated sooner.
                </Typography>
              ) : (
                <Typography as="p" data-testid="next-appointment-text">
                  No follow up scan is suggested
                </Typography>
              )}
            </div>
          </div>
          <div>
            <div className="h-16 border-y border-stone-700 bg-stone-950">
              <p
                className="px-[162px] py-[18.5px] text-xl font-bold text-neutral-200"
                data-testid="findings-title"
              >
                FINDINGS
              </p>
            </div>
            {reportData.map((sectionData) => (
              <Section key={sectionData.sectionName} sectionData={sectionData} />
            ))}
          </div>
        </div>
        <div className="border-t-4 border-neutral-800">
          <div className="mt-8 space-y-16 px-[162px]">
            <Signature
              data-testid="signature-component"
              description="Please sign the field below"
              onFinishSigning={(isSignedData) => setIsSigned(isSignedData)}
              signatureData={user?.signature}
              title="Signature"
            />
            {addSecondSignature && (
              <div data-testid="second-signature-component">
                <Typography variant="h5">Second signature:</Typography>
                <Typography
                  className="pt-2 text-neutral-400 dark:text-neutral-400"
                  variant="paragraph-sm"
                >
                  Requiring a second signature will assign this study to another selected
                  radiologist once published.
                </Typography>
                {!radiologist && (
                  <>
                    <Select
                      className={{ trigger: 'mt-4 h-[50px] w-[250px] rounded-xl' }}
                      data-testid="radiologist-select"
                      leftIcon={Edit}
                      onValueChange={(value) => onRadiologistSelectHandler(value)}
                      options={[
                        {
                          value: 'Radiologist-1',
                        },
                        {
                          value: 'Radiologist-2',
                        },
                      ]}
                      placeHolder={{
                        translationKey: 'Select Radiologist',
                      }}
                    />
                    <Typography
                      className="pt-2 text-neutral-400 dark:text-neutral-400"
                      variant="paragraph-xs"
                    >
                      Radiologist will receive the study in their dashboard
                    </Typography>
                  </>
                )}
                {radiologist && (
                  <Signature
                    onFinishSigning={(isSecondSignedData) => setIsSecondSigned(isSecondSignedData)}
                  />
                )}
              </div>
            )}
            <div className="flex w-full items-center gap-8 py-8">
              <div className="hidden">
                <Switch
                  data-testid="add-second-signature-switch"
                  isChecked={addSecondSignature}
                  onChange={onAddSecondSignatureHandler}
                />
                <Typography
                  as="label"
                  className="ml-4 text-sm font-normal text-neutral-400 dark:text-neutral-400"
                  data-testid="add-second-signature-label"
                >
                  Add second signature
                </Typography>
              </div>
              <div className="ml-auto flex flex-row">
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={isSensitive}
                    data-testid="report-sensitive-section-checkbox"
                    onChange={(event) => setIsSensitive(event.target.checked)}
                  />
                  <Typography
                    as="label"
                    className="ml-2 mr-10 text-sm font-normal text-neutral-400 dark:text-neutral-400"
                    data-testid="report-sensitive-section-label"
                  >
                    Sensitive
                  </Typography>
                </div>
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={isUrgent}
                    data-testid="report-urgent-section-checkbox"
                    onChange={(event) => setIsUrgent(event.target.checked)}
                  />
                  <Typography
                    as="label"
                    className="ml-2 mr-10 text-sm font-normal text-neutral-400 dark:text-neutral-400"
                    data-testid="report-urgent-section-label"
                  >
                    Urgent
                  </Typography>
                </div>
                <Button
                  aria-label="Submit This Report"
                  data-testid="review-sign-button"
                  disabled={!isSigned || (addSecondSignature && !isSecondSigned)}
                  onClick={handleSaveReport}
                  rightIcon={ChevronRight}
                  size="lg"
                  variant="primary"
                >
                  Submit This Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
