import { create } from 'zustand';

import { OrganValidationState, OrganValidationStatus } from './useOrganValidation.types';

export const useOrganValidation = create<OrganValidationState>((set, get) => ({
  getStatus: (organ) => get().organs[organ],
  organs: {},
  setStatus: (organ: string, status: OrganValidationStatus) => {
    set((state) => ({
      organs: {
        ...state.organs,
        [organ]: status,
      },
    }));
  },
}));
