import * as React from 'react';
import { ChevronDown, ChevronRight, ChevronUp } from '@prenuvo/halo-icon';
import { Icon, Typography } from '@prenuvo/halo-web';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { cn } from '@/lib/utils';

const Accordion = AccordionPrimitive.Root;

interface AccordionContentProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> {
  border?: boolean; // Add border prop to control border visibility
}

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> & { className?: string }
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    className={cn('border border-neutral-800 dark:border-neutral-800 rounded-xl mb-3', className)}
    ref={ref}
    {...props}
  />
));

AccordionItem.displayName = 'AccordionItem';

interface AccordionTriggerProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
  iconposition?: 'left' | 'right';
  isopen?: string;
}

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps & { className?: string }
>(({ children, className, ...props }, ref) => {
  const isOpen = props.isopen === 'true';
  const iconposition = props.iconposition || 'left';

  return (
    <AccordionPrimitive.Header className="flex h-16">
      <AccordionPrimitive.Trigger
        className={cn(
          'flex flex-1 items-center p-6 font-normal text-neutral-400 dark:text-neutral-400 data-[state=open]:text-neutral-50 dark:data-[state=open]:text-neutral-50',
          className,
        )}
        ref={ref}
        {...props}
      >
        {iconposition === 'left' && (
          <span className="inline pr-3">
            <Icon
              aria-label="Chevron"
              className="fill-current"
              size="xs"
              source={isOpen ? ChevronDown : ChevronRight}
            />
          </span>
        )}
        <Typography as="h5" variant="h5">
          {children}
        </Typography>
        {iconposition === 'right' && (
          <span className="ml-auto inline">
            <Icon
              aria-label="Chevron"
              className="fill-current"
              size="md"
              source={isOpen ? ChevronUp : ChevronDown}
            />
          </span>
        )}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
});

AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  AccordionContentProps & {
    className?: string;
  }
>(({ border = true, children, className, ...props }, ref) => (
  <AccordionPrimitive.Content
    className={cn(
      'overflow-hidden text-sm text-neutral-400 dark:text-neutral-400 data-[state=closed]:hidden',
      border && 'border-t border-neutral-800 dark:border-neutral-800',
      className,
    )}
    ref={ref}
    {...props}
  >
    <div className={cn('pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
