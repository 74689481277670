import { create } from 'zustand';

import { ReportOrgan } from '@/types/shared';

import { ReportOrganState } from './useReportOrgan.type';

export const useReportOrgan = create<ReportOrganState>((set, get) => ({
  getReportOrgan: (reportUuid, organName) => get().reportOrgans[reportUuid]?.[organName],
  reportOrgans: {},
  setReportOrgan: (reportUuid, organName, reportOrgan) => {
    set((state) => ({
      reportOrgans: {
        ...state.reportOrgans,
        [reportUuid]: {
          ...state.reportOrgans[reportUuid],
          [organName]: reportOrgan,
        },
      },
    }));
  },
  setReportOrgans: (reportUuid, reportOrgans: ReportOrgan[]) => {
    set((state) => ({
      reportOrgans: {
        ...state.reportOrgans,
        [reportUuid]: reportOrgans.reduce(
          (acc, organ) => {
            acc[organ.organ] = organ;

            return acc;
          },
          {} as { [key: string]: ReportOrgan },
        ),
      },
    }));
  },
  updateReportOrgan: (reportUuid, organName, reportOrgan) => {
    set((state) => ({
      reportOrgans: {
        ...state.reportOrgans,
        [reportUuid]: {
          ...state.reportOrgans[reportUuid],
          [organName]: reportOrgan,
        },
      },
    }));
  },
}));
