/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { StudiesResponse } from '@/types/study';

export const usePatientStudies = (
  patientID: string,
  studyId: string | undefined,
  scanDate: number,
) => {
  const hasPatientStudiesFetched = useRef(false);
  const [previousStudyIds, setPreviousStudyIds] = useState<string[]>([]);

  const fetchPatientStudies = () =>
    makeApiRequest<StudiesResponse>(
      'APPS',
      `${API_ENDPOINTS.study.getStudy}?users=${patientID}&status=scanned`,
      'GET',
    );

  const {
    data: patientStudiesData,
    error,
    isLoading,
  } = useQuery({
    enabled: !!patientID && !hasPatientStudiesFetched.current,
    queryFn: fetchPatientStudies,
    queryKey: ['patientStudies', patientID],
    refetchOnWindowFocus: false,
    retryOnMount: false,
  });

  useEffect(() => {
    if (patientStudiesData) {
      hasPatientStudiesFetched.current = true;

      if (patientStudiesData?.data?.studies?.length > 0) {
        const previousStudies = patientStudiesData?.data?.studies?.filter(
          (study) => study.studyID !== studyId && study.booking[0].utcStart < scanDate,
        );

        if (previousStudies?.length > 0) {
          const sortedStudies = previousStudies
            .sort((a, b) => b.booking[0].utcStart - a.booking[0].utcStart)
            .slice(0, 8);

          setPreviousStudyIds(sortedStudies.map((study) => study.studyID));
        } else {
          console.log('No previous studies found');
        }
      } else {
        console.log('No patient studies found');
      }
    }
  }, [patientStudiesData, hasPatientStudiesFetched]);

  return { error, isLoading, previousStudyIds };
};
