import { create } from 'zustand';

import { Progress } from '../../components/NavMenu/NavMenu.types';
import { FormStructureState } from './useFormStructure.type';

export const useFormStructure = create<FormStructureState>((set, get) => ({
  formStructure: null,
  getFormDataByOrgan: (organ: string) =>
    get().formStructure?.structure.find((data) => data.key === organ),
  setFormStructure: (formStructure) => set({ formStructure }),
  updateOrganProgress: (organ: string, newProgress: null | Progress) =>
    set((state) => {
      if (!state.formStructure) {
        return state;
      }

      const updatedStructure = state.formStructure.structure.map((data) => {
        if (data.key === organ) {
          return { ...data, progress: newProgress };
        }

        return data;
      });

      return {
        ...state,
        formStructure: {
          ...state.formStructure,
          structure: updatedStructure,
        },
      };
    }),
}));
