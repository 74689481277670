import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HaloProvider } from '@prenuvo/halo-web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from './contexts/authContext/AuthProvider';
import { ThemeProvider } from './contexts/ThemeContext/ThemeProvider';
import { routes } from './routes';

const queryClient = new QueryClient();

const router = createBrowserRouter(routes, {
  future: {
    v7_relativeSplatPath: true,
  },
});

function App() {
  return (
    <HaloProvider
      language="en"
      locales={{
        en: {
          /** Your locales here */
        },
      }}
    >
      <AuthProvider>
        <ThemeProvider defaultTheme="dark">
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ThemeProvider>
      </AuthProvider>
    </HaloProvider>
  );
}

export { App };
