import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { usePatient } from '@/store/usePatient/usePatient';
import { User } from '@/types/user';

export const usePatientDetails = (patientID: string | undefined) => {
  const { setPatient: setPatientData } = usePatient();
  const [patient, setPatient] = useState<null | User>(null);
  const hasPatientDetailsFetched = useRef(false);

  const fetchPatientDetails = () =>
    makeApiRequest<User[]>('PII', `${API_ENDPOINTS.user.getPatient}?user_id=${patientID}`, 'GET');

  const { data: patientDetailsData, isLoading } = useQuery({
    enabled: !!patientID && !hasPatientDetailsFetched.current,
    queryFn: fetchPatientDetails,
    queryKey: ['patientDetails', patientID],
  });

  useEffect(() => {
    if (patientDetailsData) {
      hasPatientDetailsFetched.current = true;

      if (patientDetailsData.data.length) {
        setPatient(patientDetailsData.data[0]);
        setPatientData(patientDetailsData.data[0]);
      } else {
        // eslint-disable-next-line no-console
        console.log('No patient details found');
      }
    }
  }, [patientDetailsData, setPatientData]);

  return { isLoading, patient };
};
