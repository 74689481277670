import { create } from 'zustand';

import { Finding, UpdateFindingDetails } from '@/types/finding';

import { FindingsState } from './useFinding.types';

export const useFinding = create<FindingsState>((set, get) => ({
  addFinding: (finding: Finding) => {
    set((state) => ({
      findings: {
        ...state.findings,
        [finding.conditionTemplate.organ ?? '']: [
          ...(state.findings[finding.conditionTemplate.organ ?? ''] || []),
          finding,
        ],
      },
    }));
  },
  deleteFinding: (organ: string, uuid: string) => {
    set((state) => {
      const organFindings = state.findings[organ] || [];
      const updatedFindings = organFindings.filter(
        (finding) => finding.renderedSummary.uuid !== uuid,
      );

      return {
        findings: {
          ...state.findings,
          [organ]: updatedFindings,
        },
      };
    });
  },
  findings: {},
  getFindingsByOrgan: (organ: string) => get().findings[organ] || [],
  setFindings: (findings: { [key: string]: Finding[] }) => {
    set({ findings });
  },
  updateFinding: (
    organ: string,
    updatedFinding: UpdateFindingDetails,
    renderedSummaryId: string,
  ) => {
    set((state) => {
      const organFindings = state.findings[organ]?.map((finding) => {
        const isSameUuid = finding.renderedSummary.uuid === renderedSummaryId;

        if (isSameUuid) {
          return {
            ...finding,
            ...updatedFinding,
          };
        }

        return finding;
      });

      return {
        ...state,
        findings: {
          ...state.findings,
          [organ]: organFindings || [updatedFinding],
        },
      };
    });
  },
}));
