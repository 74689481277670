import { useMutation } from '@tanstack/react-query';

import { Report } from '@/components/Report/Report.type';
import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { HttpMethod } from '@/core/constants';

export const useReport = (studyId: string) => {
  const mutation = useMutation({
    mutationFn: async (reportDataObj: Partial<Report>) => {
      const { data } = await makeApiRequest<Report>(
        'FCT',
        `${API_ENDPOINTS.report.saveReport}/${studyId}`,
        HttpMethod.PATCH,
        reportDataObj,
      );

      return data;
    },
  });

  return mutation;
};
