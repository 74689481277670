import { ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Loading } from '@/components/Loading';

import { config } from '../../config';
import { useAuth } from '../../hooks/queries/useAuth/useAuth';

type AuthGuardProps = {
  children: ReactNode;
};

export function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<null | string>(null);

  useEffect(() => {
    if (!isAuthenticated) {
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }

      window.location.replace(`${config.APPS_SERVER}/login?redirectUrl=${window.location.href}`);
    }
  }, [isAuthenticated, pathname, requestedLocation]);

  if (!isAuthenticated) {
    return <Loading />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);

    return <Navigate to={requestedLocation} />;
  }

  return children;
}
