import { Checkmark } from '@prenuvo/halo-icon';
import { cn, Icon, IconSource, Typography } from '@prenuvo/halo-web';

import { CSD_REPORT_MESSAGES, getColorVariants } from '@/mocks/constants';
import { transformSentenceToKebabCase } from '@/utils/utils';

import { Finding } from '../Finding/Finding';
import { organContainerVariants } from './Organ.cva';
import { OrganProps } from './Organ.type';

export function Organ({ isLast, organ }: OrganProps) {
  const formattedOrganTitle = transformSentenceToKebabCase(organ.title);

  const csdCounts = organ.findings.reduce(
    (acc, finding) => {
      acc[finding.csdScore] = (acc[finding.csdScore] || 0) + 1;

      return acc;
    },
    {} as Record<number, number>,
  );

  const highestCSD = Math.max(...Object.keys(csdCounts).map(Number));
  const highestCSDCount = csdCounts[highestCSD] || 0;

  const csdInfo =
    highestCSD > 0 ? CSD_REPORT_MESSAGES[highestCSD as keyof typeof CSD_REPORT_MESSAGES] : null;

  const csdMessage = csdInfo
    ? csdInfo.message
        .replace('{count}', `${highestCSDCount}`)
        .replace('Findings', highestCSDCount === 1 ? 'Finding' : 'Findings')
    : null;

  const allSameCSD = Object.keys(csdCounts).length === 1;

  return (
    <div
      className={cn(organContainerVariants({ last: isLast }))}
      data-testid={`${formattedOrganTitle}-organ`}
    >
      <div className="flex items-center gap-3">
        <div className={cn(csdInfo?.color)}>
          <Icon
            aria-label={organ.title}
            className={cn('size-[54px] fill-current')}
            size="lg"
            source={organ.icon as IconSource}
          />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-2xl" data-testid={`${formattedOrganTitle}-organ-title`}>
            {organ.title}
          </p>
          {csdInfo && (
            <div className={cn('border-2 p-1 rounded-lg', getColorVariants(csdInfo.color))}>
              <p className={cn(csdInfo.color, 'text-sm')}>
                {allSameCSD ? csdMessage : `${organ.findings.length} Findings, ${csdMessage}`}
              </p>
            </div>
          )}
          {organ.findings.length === 0 && organ.normals.length > 0 && (
            <Typography as="p" className="text-green-500 dark:text-green-500">
              No adverse findings
            </Typography>
          )}
        </div>
      </div>
      <div>
        <div>
          {organ.findings
            .filter((finding) => finding.observation === '')
            .map((finding, findingIndex) => (
              <Finding
                key={`${finding.organ}-${finding.uuid}`}
                finding={finding}
                isLast={
                  findingIndex ===
                  organ.findings.filter((findingData) => findingData.observation === '').length - 1
                }
                isOnDemandCondition
              />
            ))}
        </div>
        {organ.findings
          .filter((finding) => finding.observation)
          .map((finding, findingIndex) => (
            <Finding
              key={`${finding.organ}-${finding.uuid}`}
              finding={finding}
              isLast={findingIndex === organ.findings.length - 1}
            />
          ))}
        <div className="mb-8 mt-4">
          {organ.normals.map((statement) => (
            <div key={statement} className="flex items-center gap-2">
              <Icon className="fill-neutral-400" size="xs" source={Checkmark} />
              <Typography
                as="h3"
                className="text-neutral-400 dark:text-neutral-400"
                variant="paragraph-md"
              >
                {statement}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
